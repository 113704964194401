import React, { useEffect } from "react";
import Header from "../component/header/Header";
import AboutTunas from "../component/about_tunas/AboutTunas";
import ServiceInTunas from "../component/service_tunas/ServicesInTunas";
import OurPartner from "../component/our_partner/OurPartner";
import CareerInTunas from "../component/career_in_tunas/CareerInTunas";
import FloatingWhatsAppButton from "../component/floating_whatsapp_button/FloatingWhatsappButton";
import FooterTunas from "../component/footer_tunas/FooterTunas";
import ReactGA from "react-ga4";

const AboutUs = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "About Apotek Klinik Gigi Tunas",
    });
  }, []);

  const onHeaderNavigationClicked = (section) => {
    const targetElement = document.querySelector("." + section);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Header onHeaderNavigationClicked={onHeaderNavigationClicked} />
      <AboutTunas />
      <ServiceInTunas />
      <OurPartner />
      <CareerInTunas />
      <FloatingWhatsAppButton />
      <FooterTunas />
    </div>
  );
};

export default AboutUs;
