import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import AboutUs from "./pages/about-apotek-tunas/page/AboutUs";
import "./App.css";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "AW-11303697513" });
    ReactGA.initialize("G-KPRQ4TKSQQ");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AboutUs />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
